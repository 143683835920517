// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about_us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-appointments-js": () => import("./../../../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-response-js": () => import("./../../../src/pages/covid_response.js" /* webpackChunkName: "component---src-pages-covid-response-js" */),
  "component---src-pages-emergency-care-js": () => import("./../../../src/pages/emergency_care.js" /* webpackChunkName: "component---src-pages-emergency-care-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-covid-dialog-js": () => import("./../../../src/pages/index/CovidDialog.js" /* webpackChunkName: "component---src-pages-index-covid-dialog-js" */),
  "component---src-pages-index-dentists-section-js": () => import("./../../../src/pages/index/DentistsSection.js" /* webpackChunkName: "component---src-pages-index-dentists-section-js" */),
  "component---src-pages-index-four-key-points-js": () => import("./../../../src/pages/index/FourKeyPoints.js" /* webpackChunkName: "component---src-pages-index-four-key-points-js" */),
  "component---src-pages-index-gallery-js": () => import("./../../../src/pages/index/Gallery.js" /* webpackChunkName: "component---src-pages-index-gallery-js" */),
  "component---src-pages-index-google-reviews-js": () => import("./../../../src/pages/index/GoogleReviews.js" /* webpackChunkName: "component---src-pages-index-google-reviews-js" */),
  "component---src-pages-index-instagram-posts-js": () => import("./../../../src/pages/index/InstagramPosts.js" /* webpackChunkName: "component---src-pages-index-instagram-posts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-memberships-js": () => import("./../../../src/pages/index/Memberships.js" /* webpackChunkName: "component---src-pages-index-memberships-js" */),
  "component---src-pages-index-mission-statement-js": () => import("./../../../src/pages/index/MissionStatement.js" /* webpackChunkName: "component---src-pages-index-mission-statement-js" */),
  "component---src-pages-index-promo-dialog-js": () => import("./../../../src/pages/index/PromoDialog.js" /* webpackChunkName: "component---src-pages-index-promo-dialog-js" */),
  "component---src-pages-index-treatments-section-js": () => import("./../../../src/pages/index/TreatmentsSection.js" /* webpackChunkName: "component---src-pages-index-treatments-section-js" */),
  "component---src-pages-treatments-js": () => import("./../../../src/pages/treatments.js" /* webpackChunkName: "component---src-pages-treatments-js" */)
}

